import React, {useState} from "react";
import BlogListItem from "./BlogListItem";
import style from "./Filter.module.css";

const BlogList = ({data}) => {
    const [selected, setSelected] = useState("All Topics")
    const topics = [...new Set(data.map(t => t.node.topic))]
    let tags = []
    const cat = data.map(t => t.node.metaKeywords.split(',').map(item => tags.push(item.trim())))
    tags = [...new Set(tags)]

    return  (
        <section className="uk-section">
            <div className="uk-container" data-uk-filter="target: .topic-filter">
                <div className="uk-container uk-margin-medium uk-width-1-1">
                    <div className="uk-inline">
                        <p style={{fontSize: "11px", fontWeight: "bold"}} className="uk-text-muted uk-text-uppercase uk-margin-remove-bottom">Filter by</p>
                        <div className={`${style.filterButton}
                            uk-button`}>
                            {selected} <span data-uk-icon="icon: chevron-down" />
                        </div>
                        <div
                            className={`${style.filterDropdown} uk-box-shadow-medium uk-border-rounded`}
                            data-uk-toggle
                            data-uk-dropdown="mode: click; animation: uk-animation-slide-bottom-medium; offset: -40">
                            <ul className={`uk-nav uk-dropdown-nav`}>
                                <li className="uk-active" data-uk-filter-control="">
                                    <a href="#" onClick={() => setSelected("All topics")}>All Topics</a>
                                </li>
                                {topics.map((topic, i) =>
                                    <li key={topic}
                                        data-uk-filter-control={`[data-topic='${topic}']`}>
                                            <a href="#" onClick={() => setSelected(topic)}>{topic}</a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="topic-filter uk-grid uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-flex-top" data-uk-grid>
                        {data.map(post =>
                            <BlogListItem blog={post.node} key={post.node.slug} />
                        )}
                </div>
            </div>
        </section>
    )
}

export default BlogList;
