import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image";
import * as Markdown from 'react-markdown';
import {Video} from "../Video"
import style from "../Hero/Hero.module.css"
import hero from "../../images/homepage-hero.jpg";

const Header = ({ data, link }) => {
    const heroBackground = data.heroVideo ? `https://img.youtube.com/vi/${data.heroVideo.split('v=')[1].split('&')[0]}/maxresdefault.jpg` : null
    const metaImage = heroBackground ? heroBackground : `https:${data.image.resize.src}`

    return (
        heroBackground ?
            <div className={`uk-cover-container uk-light uk-flex uk-flex-middle`} style={{backgroundColor: "rgba(29, 38, 54, 0.45)"}}>
                <img
                    src={heroBackground}
                    alt={data.title}
                    className={style.youTubeImage}
                    data-uk-cover
                    data-uk-img
                    data-uk-parallax="opacity: 1,0.1; easing:0" />
                <div id={data.slug} className="uk-container uk-position-relative uk-light">
                    <div className={`${style.topWrapHeight} uk-flex uk-flex-middle`}>
                        <div
                            style={{minWidth: "80%"}}
                            className={`${style.topContainer} uk-position-relative uk-margin-medium-top`}
                            data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
                            <div
                                className={`${style.heroCopy} uk-width-1-2@s uk-position-z-index`}
                                data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                                <h1 style={{fontSize: "2.75rem"}}>{data.title}</h1>
                                <p><Link to={link}>The latest</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <BackgroundImage
                    Tag="div"
                    fluid={data.image.fluid}
                    preserveStackingContext={true}
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundColor: "rgba(29, 38, 54, 0.45)",
                        backgroundPosition: `center center`,
                        display: `flex`,
                        alignItems: `center`,
                        height: `100%`
                      }}
                  >
                  <div id={data.slug} className="uk-container uk-position-relative uk-light">
                      <div className={`${style.topWrapHeight} uk-flex uk-flex-middle`}>
                          <div
                              style={{minWidth: "80%"}}
                              className={`${style.topContainer} uk-position-relative uk-margin-medium-top`}
                              data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
                              <div
                                  className={`${style.heroCopy} uk-width-1-2@s`}
                                  data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                                  <h1 style={{fontSize: "2.75rem"}}>{data.title}</h1>
                                  <p><Link to={link}>The latest</Link></p>
                              </div>
                          </div>
                      </div>
                  </div>
            </BackgroundImage>
    )
}

export default Header
