import React from 'react';
import { Link } from "gatsby";
import Img from 'gatsby-image';
import * as Markdown from 'react-markdown';
import style from "./News.module.css";
import eye from "../../images/icons/Eye.png";

const trimString = str => {
    var length = 253;
    console.log(str)
    var trimmedString = str.substring(0, length);
    return (
        "monkey"
    )
}

const BlogListItem = props => {
    const { author, topic, metaKeywords, title, source, copy, heroImage, heroVideo, publishDate, slug } = props.blog;

    const posterImage = heroVideo ? `https://img.youtube.com/vi/${heroVideo.split('v=')[1].split('&')[0]}/maxresdefault.jpg` : null;

    return (
        <div className="uk-card uk-card-small uk-margin-large-bottom" data-topic={topic}>
            <div className="uk-card-media-top uk-transition-toggle uk-position-relative">
            {posterImage ?
                <img
                    src={posterImage} />
                :
                <Img
                    fluid={heroImage.fluid}
                    alt={heroImage.title}
                    className={`uk-border-rounded`}
                    objectFit="cover"
                    objectPosition="50% 50%"
                     />
            }
                <div className={`${style.overlayBackground} uk-transition-fade uk-position-cover uk-overlay uk-flex uk-flex-center uk-flex-middle`}>
                    <Link
                        style={{textDecoration: 'none'}}
                        to={`/blog/${slug}`}>
                    <span>
                        <img
                            src={eye}
                            alt={title}
                            style={{maxWidth: "72px"}}
                            />
                    </span>
                    </Link>
                </div>
            </div>
            <div className="uk-card-body uk-padding-small-top">
                <h3 className="uk-text-bold uk-margin-small-bottom">
                    <Link className="uk-link-text"
                        to={`/blog/${slug}`}>
                        {title}
                    </Link>
                </h3>

                <div>
                    {copy.copy.slice(0, 64)}
                </div>

                <Link style={{color: "#202020"}} className="uk-button uk-button-text cta-button"
                    to={`/blog/${slug}`}>
                Read this
                </Link>
            </div>
        </div>
    );
};

export default BlogListItem
